import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "../../util/routes";
import { Link } from "react-router-dom";

export default function EventsCalendar() {
  // All events that up to 1 month before current date and 2 months after current date
  const [events, setEvents] = useState<any[]>();
  // Get events that are on or after the current date
  useEffect(() => {
    async function getEvents() {
      const response = await axios.get(`${API_URL}/events/event/`);
      console.log("Get events");
      setEvents(response.data);
    }
    getEvents();
  }, []);
  function renderEventContent(eventInfo: any) {
    return (
      <Link className="w-full" to={`../${eventInfo.event.id}`}>
        <div className="w-full p-2 flex flex-col bg-pink-600 text-white hover:cursor-pointer rounded-sm">
          <i>{eventInfo.timeText}</i>
          <div>{eventInfo.event.title}</div>
        </div>
      </Link>
    );
  }

  const StyleWrapper = styled.div`
    a.fc-daygrid-event.fc-daygrid-dot-event.fc-event.fc-event-start.fc-event-end.fc-event-past {
      white-space: normal;
    }
  `;

  return (
    <div className="p-8">
      <div className="p-4 border rounded-md shadow-md">
        <StyleWrapper>
          <FullCalendar
            plugins={[dayGridPlugin]}
            events={events}
            displayEventEnd={true}
            initialView="dayGridMonth"
            eventContent={renderEventContent}
          />
        </StyleWrapper>
      </div>
    </div>
  );
}
