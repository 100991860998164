import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { DiscussionType, PostType } from "../../types";
import { API_URL } from "../../util/routes";
import GrayButton from "../GrayButton";
import TabButton from "../TabButton";
import Post from "./Post";
import { MdArrowBack } from "react-icons/md";

function Discussion() {
  const [posts, setPosts] = useState<PostType[]>();
  const [postBody, setPostBody] = useState<string>();
  const [firstPost, setFirstPost] = useState<PostType>();
  const [discussion, setDiscussion] = useState<DiscussionType>();
  const [authorName, setAuthorName] = useState<string>();
  const params = useParams();

  useEffect(() => {
    // Getting the discussions (threads) in the forum
    const getDiscussionData = async () => {
      let response = await axios.get(
        `${API_URL}/forums/${params.forum_id}/discussions/${params.discussion_id}/`
      );
      let discussion = response.data;
      setDiscussion(discussion);
      console.log("Get Posts");
      if (!discussion) {
        return;
      }
      response = await axios.get(
        `${API_URL}/forums/${discussion.forum}/discussions/${discussion.id}/posts`
      );
      let posts = response.data;
      posts.shift();
      setPosts(posts.reverse());

      console.log("Get First Post");
      if (!discussion) {
        return;
      }
      response = await axios.get(
        `${API_URL}/forums/${discussion.forum}/discussions/${discussion.id}/posts/first/`
      );
      let first_post = response.data;
      setFirstPost(first_post);
      response = await axios.get(
        `${API_URL}/profiles/profile/${discussion.author}`
      );
      setAuthorName(`${response.data.first_name} ${response.data.last_name}`);
    };
    getDiscussionData();
  }, [params.discussion_id, params.forum_id]);

  const makePost = async () => {
    if (discussion) {
      const response = await axios.post(
        `${API_URL}/forums/${discussion.forum}/discussions/${discussion.id}/posts/`,
        { body: postBody }
      );
      if (posts) {
        setPosts([response.data, ...posts]);
        console.log("Posted");
      }
    }
  };

  return (
    <div className="flex flex-col gap-3">
      <Link
        to={`/dashboard/groups/${params.group_id}/forum/${discussion?.forum}`}
      >
        <TabButton extraStyles="text-blue-600">
          <MdArrowBack />
          Go Back
        </TabButton>
      </Link>
      <div className="flex flex-row gap-3 items-center">
        <div className="text-2xl">{discussion?.title}</div>
        <div className="text-gray-400 text-sm flex flex-col">
          posted by {authorName} {moment(discussion?.date).fromNow()}
        </div>
      </div>
      <div className="border-b pb-3">
        {firstPost?.body ? (
          <Post key={firstPost.id} post={firstPost} hideHeader />
        ) : null}
      </div>
      <div className="flex flex-col items-center gap-3 p-3 border-b">
        <textarea
          className="outline-none border rounded-sm placeholder:text-gray-500 p-3 w-5/6 h-20"
          placeholder="What are your thoughts?"
          value={postBody}
          onChange={(e) => {
            setPostBody(e.target.value);
          }}
        />
        <GrayButton
          onClick={() => {
            makePost();
            setPostBody("");
          }}
        >
          Comment
        </GrayButton>
      </div>
      <div className="flex flex-col gap-4 ml-4">
        {posts?.map((post) => {
          return <Post key={post.id} post={post} />;
        })}
      </div>
    </div>
  );
}

export default Discussion;
