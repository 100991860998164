import axios from "axios";
import { useEffect, useState } from "react";
import { GroupType } from "../../types";
import { GET_ALL_GROUPS_URL, GET_USER_GROUPS_URL } from "../../util/routes";
import GroupListItem from "./GroupListItem";
import { MdGroup, MdSearch } from "react-icons/md";
import TabButton from "../TabButton";
import RectangleRow from "../RectangleRow";

function GroupList({ setGroup }: any) {
  const [userGroups, setUserGroups] = useState<GroupType[]>();
  const [exploreGroups, setExploreGroups] = useState<GroupType[]>();
  const [page, setPage] = useState<"Explore" | "Joined Groups">(
    "Joined Groups"
  );

  useEffect(() => {
    const getGroups = async () => {
      let response = await axios.get(GET_USER_GROUPS_URL);
      let data = response.data;
      let userTemp = data;

      response = await axios.get(GET_ALL_GROUPS_URL);
      data = response.data;
      let allIDS = [];
      let userIDS: number[] = [];
      for (let i = 0; i < data.length; i++) {
        allIDS.push(data[i].id);
      }
      if (userTemp) {
        for (let i = 0; i < userTemp.length; i++) {
          userIDS.push(userTemp[i].id);
        }
      }
      const exploreIDS = allIDS.filter((n) => !userIDS.includes(n));
      let temp = [];
      for (let i = 0; i < exploreIDS.length; i++) {
        temp.push(data.find((x: GroupType) => x.id === exploreIDS[i]));
      }
      setUserGroups(userTemp);
      setExploreGroups(temp);
    };
    getGroups();
  }, []);

  return (
    <div className="flex flex-col items-center gap-6">
      <div className="bg-gradient-to-r from-indigo-500 to-fuchsia-600 p-5 flex flex-col w-full justify-center items-center shadow-sm">
        <div className="text-4xl font-bold text-white">Groups</div>
        <div className="text-white">View your groups or explore others.</div>
      </div>
      <RectangleRow>
        <TabButton
          disabled={page === "Joined Groups"}
          onClick={() => {
            setPage("Joined Groups");
          }}
        >
          <MdGroup className="text-blue-600" /> Your Groups
        </TabButton>
        <TabButton
          disabled={page === "Explore"}
          onClick={() => {
            setPage("Explore");
          }}
        >
          <MdSearch className="text-blue-600" /> Explore
        </TabButton>
      </RectangleRow>
      <div className="flex md:flex-row flex-col flex-wrap gap-5 max-w-4/5">
        {page === "Explore"
          ? exploreGroups?.map((group) => {
              return (
                <GroupListItem key={group.id} group={group} userGroup={false} />
              );
            })
          : null}
        {page === "Joined Groups"
          ? userGroups?.map((group) => {
              return (
                <GroupListItem
                  key={group.id}
                  group={group}
                  userGroup={true}
                  setGroup={setGroup}
                />
              );
            })
          : null}
      </div>
    </div>
  );
}

export default GroupList;
