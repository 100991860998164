import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ResourceSubSectionType, ResourceType } from "../../types";
import { API_URL } from "../../util/routes";
import DeleteButton from "../DeleteButton";
import GrayButton from "../GrayButton";
import ResourceItem from "./ResourceItem";

export default function ResourceSubSection({
  resourceSubSection,
  isGroupAdmin,
}: {
  resourceSubSection: ResourceSubSectionType;
  isGroupAdmin: boolean;
}) {
  const [resources, setResources] = useState<ResourceType[]>([]);
  const [isAddResource, setIsAddResource] = useState(false);
  const [file, setFile] = useState<File | undefined>();
  const [link, setLink] = useState("");
  const [resourceName, setResourceName] = useState("");
  const [description, setDescription] = useState("");
  const params = useParams();

  useEffect(() => {
    async function getResources() {
      console.log("Got resources");
      const response = await axios.get(
        `${API_URL}/groups/${params.group_id}/resources?resource_subsection=${resourceSubSection.id}`
      );
      setResources(response.data);
    }
    getResources();
  }, [params.group_id, resourceSubSection.id]);

  async function addResource() {
    if (link.trim() === "" && file === null) {
      return;
    }
    console.log("add resource");
    let response;

    const formData = new FormData();
    formData.append("name", resourceName);
    formData.append("resource_subsection", resourceSubSection.id.toString());
    formData.append("description", description);
    if (file) {
      const array_buffer = await file.arrayBuffer();
      const blob = new Blob([new Uint8Array(array_buffer)], {
        type: file.type,
      });
      formData.append("file", blob, file.name);

      response = await axios.post(
        `${API_URL}/groups/${params.group_id}/resources/`,
        formData
      );
    } else {
      formData.append("link", link);
      response = await axios.post(
        `${API_URL}/groups/${params.group_id}/resources/`,
        formData
      );
    }
    console.log(response.data);
    setResources([...resources, response.data]);
  }

  async function deleteSubSection() {
    if (window.confirm("Delete sub section? (Refresh to see changes)")) {
      await axios.delete(
        `${API_URL}/groups/${params.group_id}/subsections/${resourceSubSection.id}`
      );
      console.log("deleted");
    }
  }

  return (
    <div className="w-full border rounded-md shadow-sm p-4">
      <div className="flex flex-row justify-between">
        <div className="text-lg">{resourceSubSection.name}</div>
        {isGroupAdmin ? (
          <DeleteButton onClick={deleteSubSection}>Delete</DeleteButton>
        ) : null}
      </div>
      <div className="mt-3 flex flex-col gap-1">
        {resources?.map((resource) => {
          return (
            <ResourceItem
              key={resource.id}
              resource={resource}
              isGroupAdmin={isGroupAdmin}
            />
          );
        })}
        {isAddResource ? (
          <div className="border rounded-md shadow-sm p-2 flex flex-col gap-3 items-center w-2/3 my-3 m-auto">
            <div className="text-md">Add Resource</div>
            <input
              placeholder="Resource Name"
              className="outline-none border rounded-md w-1/2 p-1 text-sm"
              value={resourceName}
              onChange={(e) => setResourceName(e.target.value)}
            />
            <textarea
              placeholder="Resource Description"
              className="outline-none border rounded-md w-1/2 p-1 text-sm"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <input
              placeholder="Resource Link"
              className="outline-none border rounded-md w-1/2 p-1 text-sm"
              value={link}
              onChange={(e) => setLink(e.target.value)}
            />
            or
            <input
              placeholder="Resource File"
              className="outline-none border rounded-md w-1/2 p-1 text-sm cursor-pointer file:bg-white file:border-y-0 file:border-l-0 file:border-r"
              type="file"
              onChange={(e) => {
                if (e.target.files) {
                  setFile(e.target.files[0]);
                }
              }}
            />
            <div className="flex flex-row gap-3">
              <GrayButton
                onClick={() => {
                  setIsAddResource(false);
                }}
                extraStyles="text-sm"
              >
                Cancel
              </GrayButton>
              <GrayButton
                extraStyles="text-sm"
                onClick={() => {
                  addResource();
                  setResourceName("");
                  setDescription("");
                  setLink("");
                  setFile(undefined);
                  setIsAddResource(false);
                }}
              >
                Add
              </GrayButton>
            </div>
          </div>
        ) : null}
        {isGroupAdmin ? (
          <GrayButton
            onClick={() => {
              setIsAddResource(true);
            }}
          >
            Add Resource
          </GrayButton>
        ) : null}
      </div>
    </div>
  );
}
