import axios from "axios";
import { useEffect, useState } from "react";
import { Route, Routes, useParams } from "react-router-dom";
import { ResourceSectionType } from "../../types";
import { API_URL } from "../../util/routes";
import GrayButton from "../GrayButton";
import ResourceButton from "./ResourceButton";
import ResourceSection from "./ResourceSection";

// The resources section in groups
export default function Resources({ isGroupAdmin }: { isGroupAdmin: boolean }) {
  const [resourceSections, setResourceSections] =
    useState<ResourceSectionType[]>();
  const params = useParams();
  const [isCreateSection, setIsCreateSection] = useState(false);
  const [sectionName, setSectionName] = useState("");
  const [sectionDescription, setSectionDescription] = useState("");

  useEffect(() => {
    async function getResourceSections() {
      console.log("Get resource sections");
      const response = await axios.get(
        `${API_URL}/groups/${params.group_id}/sections/`
      );
      setResourceSections(response.data);
    }
    getResourceSections();
  }, [params.group_id]);

  async function createSection() {
    if (sectionName?.trim() === "") {
      return;
    }
    console.log("Create section");
    const response = await axios.post(
      `${API_URL}/groups/${params.group_id}/sections/`,
      {
        name: sectionName,
        description: sectionDescription,
        group: params.group_id,
      }
    );
    if (resourceSections) {
      setResourceSections([...resourceSections, response.data]);
    } else setResourceSections(response.data);
  }

  return (
    <div className="w-5/6">
      <Routes>
        <Route
          path=""
          element={
            <div>
              <div className="flex flex-col items-center gap-3">
                {resourceSections?.map((resourceSection) => {
                  return (
                    <ResourceButton
                      key={resourceSection.id}
                      resourceSection={resourceSection}
                      isGroupAdmin={isGroupAdmin}
                    />
                  );
                })}
                {isCreateSection ? (
                  <div className="p-5 border rounded shadow-sm w-full flex flex-col gap-3 items-center">
                    <div>Create Section</div>
                    <input
                      placeholder="Resource Title"
                      className="outline-none border rounded-md w-5/6 p-2"
                      value={sectionName}
                      onChange={(e) => {
                        setSectionName(e.target.value);
                      }}
                    />
                    <textarea
                      placeholder="Resource Description"
                      className="w-5/6 outline-none border rounded-md p-2"
                      value={sectionDescription}
                      onChange={(e) => {
                        setSectionDescription(e.target.value);
                      }}
                    />
                    <div className="flex flex-row gap-3">
                      <GrayButton
                        onClick={() => {
                          setIsCreateSection(false);
                        }}
                      >
                        Cancel
                      </GrayButton>
                      <GrayButton
                        onClick={() => {
                          createSection();
                          setSectionName("");
                          setSectionDescription("");
                          setIsCreateSection(false);
                        }}
                      >
                        Create
                      </GrayButton>
                    </div>
                  </div>
                ) : null}
                {isGroupAdmin ? (
                  <GrayButton
                    onClick={() => {
                      setIsCreateSection(true);
                    }}
                  >
                    Add Resource
                  </GrayButton>
                ) : null}
              </div>
            </div>
          }
        />
        <Route
          path=":section_id"
          element={<ResourceSection isGroupAdmin={isGroupAdmin} />}
        />
      </Routes>
    </div>
  );
}
