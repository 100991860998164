import { useState } from "react";
import GroupList from "./GroupList";
import { GroupType } from "../../types";
import Group from "./Group";
import { Route, Routes } from "react-router-dom";

function GroupMain() {
  const [group, setGroup] = useState<GroupType>();
  return (
    <div>
      <Routes>
        <Route element={<Group group={group} />} path=":group_id/*" />
        <Route element={<GroupList setGroup={setGroup} />} path="" />
      </Routes>
    </div>
  );
}

export default GroupMain;
