import axios from "axios";
import { LOGIN_URL } from "../util/routes";

/**
 * Sets the auth token for axios, if the token param is empty then delete the token from local storage
 * @param token The JWT token
 */
export function setAuthHeader(token: string) {
  console.log(token);
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Token ${token}`;
    localStorage.setItem("token", token);
  } else {
    localStorage.removeItem("token");
    axios.defaults.headers.common["Authorization"] = "";
  }
}

/**
 * Sends post request to log the user in
 * @param email
 * @param password
 * @returns The auth token
 */
export async function login(email: string, password: string) {
  let response = await axios.post(LOGIN_URL, {
    email,
    password,
  });
  if (response.status === 401) {
    return false;
  }
  let token = response.data.token;
  setAuthHeader(token);
  return true;
}
