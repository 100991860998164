export default function RectangleRow({
  children,
  extraStyles,
}: {
  children: any;
  extraStyles?: string;
}) {
  return (
    <div
      className={`flex flex-row gap-4 flex-wrap items-center justify-center p-4 border rounded-md ${extraStyles}`}
    >
      {children}
    </div>
  );
}
