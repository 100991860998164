import axios from "axios";
import { useParams } from "react-router-dom";
import { ResourceType } from "../../types";
import { API_URL } from "../../util/routes";
import DeleteButton from "../DeleteButton";

export default function ResourceItem({
  resource,
  isGroupAdmin,
}: {
  resource: ResourceType;
  isGroupAdmin: boolean;
}) {
  const params = useParams();
  async function deleteResource() {
    if (window.confirm("Delete Resource? (Refresh to see changes)")) {
      await axios.delete(
        `${API_URL}/groups/${params.group_id}/resources/${resource.id}`
      );
      console.log("deleted");
    }
  }
  return (
    <div className="w-full flex flex-row justify-between items-center pl-4">
      <div>
        {`${resource.name}: `}
        {resource.file ? (
          <a
            target="_blank"
            rel="noreferrer"
            href={`${API_URL}${resource.file}`}
            className="text-blue-600"
          >
            File
          </a>
        ) : (
          <a
            target="_blank"
            rel="noreferrer"
            href={resource.link}
            className="text-blue-600"
          >
            Link
          </a>
        )}
        <div className="text-gray-500 text-sm">{resource.description}</div>
      </div>
      {isGroupAdmin ? (
        <DeleteButton onClick={deleteResource}>Delete</DeleteButton>
      ) : null}
    </div>
  );
}
