import { Route, Routes } from "react-router-dom";
import EventsCalendar from "./EventsCalendar";
import EventView from "./EventView";

function EventsMain() {
  return (
    <div>
      <div className="bg-gradient-to-r from-fuchsia-500 to-rose-500 p-5 flex flex-col w-full justify-center items-center shadow-sm">
        <div className="text-4xl font-bold text-white">Event Calender</div>
        <div className="text-white">View and register for events.</div>
      </div>
      <Routes>
        <Route path="/calendar" element={<EventsCalendar />} />
        <Route path="/:event_id" element={<EventView />} />
      </Routes>
    </div>
  );
}

export default EventsMain;
