import axios from "axios";
import { Link } from "react-router-dom";
import { ResourceSectionType } from "../../types";
import { API_URL } from "../../util/routes";
import DeleteButton from "../DeleteButton";

export default function ResourceButton({
  resourceSection,
  isGroupAdmin,
}: {
  resourceSection: ResourceSectionType;
  isGroupAdmin: boolean;
}) {
  async function deleteResourceSection() {
    if (window.confirm("Delete Resource Section? (Refresh to see changes)")) {
      await axios.delete(
        `${API_URL}/groups/${resourceSection.group}/resources/sections/${resourceSection.id}`
      );
      console.log("deleted");
    }
  }
  return (
    <div className="border rounded-md p-4 w-full text-left shadow-sm flex flex-row justify-between items-center">
      <Link
        className="flex-1"
        to={`/dashboard/groups/${resourceSection.group}/resources/${resourceSection.id}`}
      >
        {resourceSection.name}
        <div className="text-sm text-gray-500">
          Created by {resourceSection.author}
        </div>
      </Link>
      {isGroupAdmin ? (
        <DeleteButton onClick={deleteResourceSection}>Delete</DeleteButton>
      ) : null}
    </div>
  );
}
