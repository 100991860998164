export default function GrayButton({
  onClick,
  children,
  extraStyles,
  disabled,
}: {
  onClick?: (x: any) => any;
  children: any;
  extraStyles?: string;
  disabled?: boolean;
}) {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={`border p-2 rounded-md w-fit shadow-sm m-auto disabled:shadow-none disabled:text-gray-400 ${extraStyles}`}
    >
      {children}
    </button>
  );
}
