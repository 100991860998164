import { Link, Route, Routes } from "react-router-dom";
import TabButton from "../TabButton";
import Profile from "./Profile";
import { MdPerson, MdMessage, MdSettings } from "react-icons/md";
import RectangleRow from "../RectangleRow";

export default function ProfileMain() {
  return (
    <div className="flex flex-col gap-6 items-center">
      <div className="bg-gradient-to-r from-teal-400 to-cyan-600 p-5 flex flex-col w-full justify-center items-center shadow-sm">
        <div className="text-4xl font-bold text-white">Profile</div>
        <div className="text-white">View or edit your account.</div>
      </div>
      <RectangleRow>
        <Link to="/dashboard/profile/profile/">
          <TabButton>
            <MdPerson className="text-blue-600" /> Profile
          </TabButton>
        </Link>
        <TabButton>
          <MdMessage className="text-blue-600" /> Messages
        </TabButton>
        <TabButton>
          <MdSettings className="text-blue-600" />
          Settings
        </TabButton>
      </RectangleRow>
      <Routes>
        <Route path="/profile/*" element={<Profile />} />
      </Routes>
    </div>
  );
}
