import axios from "axios";
import { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { DiscussionType, ForumType } from "../../types";
import { API_URL } from "../../util/routes";
import GrayButton from "../GrayButton";
import Discussion from "./Discussion";
import DiscussionButton from "./DiscussionButton";

function Forum({ forum }: { forum: ForumType | undefined }) {
  const [discussions, setDiscussions] = useState<DiscussionType[]>();
  const [discussionTitle, setDiscussionTitle] = useState("");
  const [discussionPostBody, setDiscussionPostBody] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    // Getting the discussions (threads) in the forum
    const getDiscussions = async () => {
      console.log("Get Discussions");
      if (!forum) {
        return;
      }
      const response = await axios.get(
        `${API_URL}/forums/${forum.id}/discussions`
      );
      const data = response.data;
      setDiscussions(data.reverse());
    };
    getDiscussions();
  }, [forum]);

  const postDiscussion = async () => {
    if (forum && discussionTitle.trim() !== "") {
      const response = await axios.post(
        `${API_URL}/forums/${forum.id}/discussions/`,
        { title: discussionTitle }
      );
      let discussion = response.data;
      if (discussionPostBody.trim() !== "") {
        await axios.post(
          `${API_URL}/forums/${forum.id}/discussions/${discussion.id}/posts/`,
          { body: discussionPostBody }
        );
      }
      if (discussions) {
        setDiscussions([discussion, ...discussions]);
      }
      console.log("Posted");
      return [true, discussion];
    }
    return [false, null];
  };

  return (
    <div className="w-5/6">
      <Routes>
        <Route
          element={
            <div className="w-full flex flex-col gap-6 md:flex-row-reverse p-4 md:items-start items-center justify-center">
              <div className="flex flex-col gap-3 border items-center rounded-md p-3 shadow-sm h-fit w-full md:w-1/3">
                <div className="text-lg">Post a Discussion</div>
                <input
                  placeholder="Discussion Title"
                  type="text"
                  className="focus:outline-none border rounded-sm placeholder:text-gray-500 p-3 w-full"
                  value={discussionTitle}
                  onChange={(e) => {
                    setDiscussionTitle(e.target.value);
                  }}
                />
                <textarea
                  className="outline-none border rounded-sm placeholder:text-gray-500 p-3 w-full h-20"
                  placeholder="What are your thoughts?"
                  value={discussionPostBody}
                  onChange={(e) => {
                    setDiscussionPostBody(e.target.value);
                  }}
                />
                <GrayButton
                  onClick={async () => {
                    const [posted, discussion] = await postDiscussion();
                    if (posted) {
                      setDiscussionTitle("");
                      setDiscussionPostBody("");
                      navigate(
                        `/dashboard/groups/${forum?.group}/forum/${forum?.id}/discussion/${discussion.id}`
                      );
                    } else {
                      window.alert("Error: Discussion title is empty.");
                    }
                  }}
                >
                  Post
                </GrayButton>
              </div>
              <div className="w-full md:flex-grow flex flex-col gap-2">
                {discussions?.map((discussion) => {
                  return (
                    <DiscussionButton
                      key={discussion.id}
                      discussion={discussion}
                    />
                  );
                })}
              </div>
            </div>
          }
          path=""
        />
        <Route element={<Discussion />} path="/discussion/:discussion_id" />
      </Routes>
    </div>
  );
}

export default Forum;
