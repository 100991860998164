import GroupMain from "../components/Group/GroupMain";
import { Link, Route, Routes } from "react-router-dom";
import ProfileMain from "../components/Profile/ProfileMain";
import ServicesMain from "../components/Services/ServicesMain";
import EventsMain from "../components/Events/EventsMain";

function Dashboard() {
  return (
    <div className="w-full h-full pb-8">
      <div className="w-full border-b text-md py-3 pl-4 sticky shadow-sm top-0 flex flex-row gap-9 items-center bg-white z-30">
        <img src="/aimga-logo.png" className="max-h-7" alt="AIMGA" />
        <Link to="/dashboard/groups">Groups</Link>
        <Link to="/dashboard/profile/profile">Profile</Link>
        <Link to="/dashboard/services">Services</Link>
        <Link to="/dashboard/events/calendar">Events</Link>
      </div>
      <Routes>
        <Route element={<GroupMain />} path="groups/*" />
        <Route element={<ProfileMain />} path="profile/*" />
        <Route element={<ServicesMain />} path="services/*" />
        <Route element={<EventsMain />} path="events/*" />
      </Routes>
    </div>
  );
}

export default Dashboard;
