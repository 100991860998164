import { useNavigate } from "react-router-dom";
import { GroupType } from "../../types";
import GrayButton from "../GrayButton";

function GroupListItem({
  group,
  userGroup,
  setGroup,
}: {
  group: GroupType;
  userGroup: boolean;
  setGroup?: any;
}) {
  const navigate = useNavigate();
  return (
    <div
      className={userGroup ? "hover:cursor-pointer" : ""}
      onClick={(e) => {
        e.stopPropagation();
        if (userGroup) {
          setGroup(group);
          navigate(`/dashboard/groups/${group.id}`);
        }
      }}
    >
      <div className="border rounded-lg shadow-sm p-9 flex flex-col items-center justify-center text-center gap-5 w-64 bg-white">
        <div className="text-xl">{group.name}</div>
        <div>
          {userGroup ? (
            <GrayButton
              onClick={(e) => {
                e.stopPropagation();
                console.log("Leave Group");
              }}
              extraStyles={"shadow-none"}
            >
              Leave Group
            </GrayButton>
          ) : (
            <GrayButton
              onClick={(e) => {
                e.stopPropagation();
                console.log("Request Membership");
              }}
              extraStyles={"shadow-none"}
            >
              Request Membership
            </GrayButton>
          )}
        </div>
      </div>
    </div>
  );
}

export default GroupListItem;
