import axios from "axios";
import { useEffect, useState } from "react";
import { MdArrowBack } from "react-icons/md";
import { Link, useParams } from "react-router-dom";
import { ResourceSectionType, ResourceSubSectionType } from "../../types";
import { API_URL } from "../../util/routes";
import GrayButton from "../GrayButton";
import TabButton from "../TabButton";
import ResourceSubSection from "./ResourceSubSection";

export default function ResourceSection({
  isGroupAdmin,
}: {
  isGroupAdmin: boolean;
}) {
  const [section, setSection] = useState<ResourceSectionType>();
  const [subSections, setSubSections] = useState<ResourceSubSectionType[]>();
  const [isCreateSection, setIsCreateSection] = useState(false);
  const [subSectionName, setSubSectionName] = useState<string>("");
  const params = useParams();
  useEffect(() => {
    async function getData() {
      console.log("Get sections");
      // Getting the section data
      let response = await axios.get(
        `${API_URL}/groups/${params.group_id}/sections/${params.section_id}/`
      );
      setSection(response.data);
      console.log("Get subsections");
      response = await axios.get(
        `${API_URL}/groups/${params.group_id}/subsections?resource_section=${params.section_id}`
      );
      setSubSections(response.data);
    }
    getData();
  }, [params.group_id, params.section_id]);
  async function createSubSection() {
    if (subSectionName?.trim() === "") {
      return;
    }
    console.log("Create sub section");
    const response = await axios.post(
      `${API_URL}/groups/${params.group_id}/subsections/`,
      {
        resource_section: section?.id,
        name: subSectionName,
      }
    );
    if (subSections) {
      setSubSections([...subSections, response.data]);
    } else setSubSections(response.data);
  }
  return (
    <div>
      <Link to={`/dashboard/groups/${params.group_id}/resources`}>
        <TabButton extraStyles="text-blue-600">
          <MdArrowBack />
          Go Back
        </TabButton>
      </Link>
      <div className="flex flex-col items-center gap-3 w-full">
        <div className="text-xl">{section?.name}</div>
        <div className="text-gray-500">{section?.description}</div>
        <div className="w-full flex flex-col gap-3">
          {subSections?.map((subsection) => {
            return (
              <ResourceSubSection
                key={subsection.id}
                resourceSubSection={subsection}
                isGroupAdmin={isGroupAdmin}
              />
            );
          })}
          {isCreateSection ? (
            <div className="border rounded-md shadow-sm p-3 flex flex-col gap-3 items-center">
              <div>Create Section</div>
              <input
                placeholder="Section Name"
                className="outline-none border rounded-md w-5/6 p-2"
                value={subSectionName}
                onChange={(e) => {
                  setSubSectionName(e.target.value);
                }}
              />
              <div className="flex flex-row gap-3">
                <GrayButton
                  onClick={() => {
                    setIsCreateSection(false);
                  }}
                >
                  Cancel
                </GrayButton>
                <GrayButton
                  onClick={() => {
                    createSubSection();
                    setSubSectionName("");
                    setIsCreateSection(false);
                  }}
                >
                  Create
                </GrayButton>
              </div>
            </div>
          ) : null}
        </div>
        {isGroupAdmin ? (
          <GrayButton
            onClick={() => {
              setIsCreateSection(true);
            }}
          >
            Add Section
          </GrayButton>
        ) : null}
      </div>
    </div>
  );
}
