import axios from "axios";
import { useEffect, useState } from "react";
import { ForumType, GroupType, ProfileType } from "../../types";
import { API_URL } from "../../util/routes";
import Forum from "../Forum/Forum";
import { Link, useParams } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import Resources from "../Resources/Resources";
import TabButton from "../TabButton";
import { MdForum, MdPermMedia, MdBuild } from "react-icons/md";
import RectangleRow from "../RectangleRow";
import GroupDescription from "./GroupDescription";
import { AiFillHome } from "react-icons/ai";

function Group({ group: groupData }: { group: GroupType | undefined }) {
  const [forum, setForum] = useState<ForumType>();
  const [page, setPage] = useState<"Forum" | "Home" | "Resources" | "Messages">(
    "Home"
  );
  const [group, setGroup] = useState(groupData);
  const [isGroupAdmin, setIsGroupAdmin] = useState(false);
  const [admins, setAdmins] = useState<ProfileType[]>();
  const params = useParams();

  // Loading the data for the group
  useEffect(() => {
    async function getGroup() {
      console.log("Get Group");
      const response = await axios.get(`${API_URL}/groups/${params.group_id}`);
      setGroup(response.data);
    }

    async function getGroupForum() {
      console.log("Get Forum");
      const response = await axios.get(
        `${API_URL}/groups/${params.group_id}/forum`
      );
      const data = response.data;
      setForum(data);
    }
    async function isAdmin() {
      console.log("Get membership");
      const response = await axios.get(
        `${API_URL}/groups/${params.group_id}/membership`
      );
      const data = response.data;
      setIsGroupAdmin(data.is_admin);
    }
    async function getAdmins() {
      console.log("Get admins");
      const response = await axios.get(
        `${API_URL}/groups/${params.group_id}/admins`
      );
      setAdmins(response.data);
    }
    getGroup();
    getGroupForum();
    isAdmin();
    getAdmins();
  }, [params.group_id]);

  return (
    <div className="flex flex-col gap-6 items-center">
      <div className="flex flex-col justify-center items-center bg-gradient-to-r from-purple-600 to-indigo-600 p-5 w-full shadow-sm">
        <div className="text-4xl font-bold text-white text-center w-full">
          {group?.name}
        </div>
        <div className="text-white">
          View group forums, messages and resources.
        </div>
      </div>
      <div>
        <div className="flex flex-col gap-1 w-56 items-center justify-center border rounded-md py-2 px-3">
          <div className="flex flex-row justify-center items-center gap-1">
            Group Admins
          </div>
          <div className="flex flex-row gap-4">
            {admins?.map((profile) => {
              return (
                <img
                  key={profile.id}
                  className="w-10 rounded-md shadow"
                  src="/default_profile.png"
                  alt={`${profile.first_name} ${profile.last_name}`}
                  title={`${profile.first_name} ${profile.last_name}`}
                />
              );
            })}
          </div>
        </div>
      </div>
      <RectangleRow extraStyles="w-5/6 md:w-fit">
        <div className="flex flex-row gap-3 w-full flex-wrap justify-center items-center">
          <Link to="">
            <TabButton
              disabled={page === "Home"}
              onClick={() => {
                setPage("Home");
              }}
            >
              <AiFillHome className="text-blue-600" />
              Home
            </TabButton>
          </Link>
          <Link to={`/dashboard/groups/${group?.id}/forum/${forum?.id}`}>
            <TabButton
              disabled={page === "Forum"}
              onClick={() => {
                setPage("Forum");
              }}
            >
              <MdForum className="text-blue-600" />
              Forum
            </TabButton>
          </Link>
          <Link to={`/dashboard/groups/${group?.id}/resources`}>
            <TabButton
              disabled={page === "Resources"}
              onClick={() => {
                setPage("Resources");
              }}
            >
              <MdPermMedia className="text-blue-600" />
              Resources
            </TabButton>
          </Link>
          {isGroupAdmin ? (
            <TabButton>
              <MdBuild className="text-blue-600" />
              Settings
            </TabButton>
          ) : null}
        </div>
      </RectangleRow>
      <Routes>
        <Route
          path="/"
          element={<GroupDescription description={group?.description} />}
        />
        <Route path="/forum/:forum_id/*" element={<Forum forum={forum} />} />
        <Route
          path="/resources/*"
          element={<Resources isGroupAdmin={isGroupAdmin} />}
        />
      </Routes>
    </div>
  );
}

export default Group;
