import axios from "axios";
import { useEffect, useState } from "react";
import {
  ActivityType,
  ProfileType,
  ServiceType,
  UserHistoryType,
} from "../../types";
import { API_URL } from "../../util/routes";
import GrayButton from "../GrayButton";

export default function ServicesMain() {
  const [users, setUsers] = useState<ProfileType[]>();
  const [selectedUser, setSelectedUser] = useState<ProfileType>();
  const [userHistory, setUserHistory] = useState<UserHistoryType[]>();
  const [serviceTypes, setServiceTypes] = useState<ServiceType[]>();
  const [selectedServiceType, setSelectedServiceType] = useState<ServiceType>();
  const [staffMembers, setStaffMembers] = useState<ProfileType[]>();
  const [selectedStaffMember, setSelectedStaffMember] = useState<ProfileType>();
  const [serviceDate, setServiceDate] = useState<string>();
  const [activities, setActivities] = useState<ActivityType[]>();
  const [serviceCreator, setServiceCreator] = useState(false);
  const [duration, setDuration] = useState<number>();
  const [notes, setNotes] = useState<string>();
  const [selectedActivity, setSelectedActivity] = useState<ActivityType>();
  const [complete, setComplete] = useState<boolean>();

  // Getting all the users in the database
  useEffect(() => {
    async function getUsers() {
      let response = await axios.get(`${API_URL}/profiles/profile/`);
      setUsers(response.data);
      response = await axios.get(`${API_URL}/services/types/`);
      setServiceTypes(response.data);
      response = await axios.get(`${API_URL}/services/activities/`);
      setActivities(response.data);
      response = await axios.get(`${API_URL}/profiles/staff/`);
      setStaffMembers(response.data);
    }
    getUsers();
  }, []);

  useEffect(() => {
    const fields = [selectedUser, selectedServiceType, serviceDate];
    fields.forEach((field) => {
      if (field === undefined || field === "" || field === null) {
        setComplete(false);
      } else {
        setComplete(true);
      }
    });
  }, [selectedUser, selectedServiceType, serviceDate]);

  // Setting the user's history whenever the selected user changes
  useEffect(() => {
    async function getUserHistory() {
      if (selectedUser === undefined) {
        return;
      }
      const response = await axios.get(
        `${API_URL}/services/profile/${selectedUser?.id}`
      );
      setUserHistory(response.data);
    }
    getUserHistory();
  }, [selectedUser]);

  const getStaffName = (record: UserHistoryType) => {
    const user = users?.find((user) => user.id === record.staff_member);
    return `${user?.first_name} ${user?.last_name}`;
  };

  const addService = async () => {
    const response = await axios.post(`${API_URL}/services/services/`, {
      provided_to: selectedUser?.id,
      date: serviceDate,
      service_type: selectedServiceType?.id,
      duration_minutes: duration,
      notes: notes,
      staff_member: selectedStaffMember?.id,
      activity: selectedActivity?.id,
    });
    console.log(response.data);
  };

  return (
    <div className="flex flex-col gap-6 items-center">
      <div className="bg-gradient-to-r from-purple-400 to-indigo-600 p-5 flex flex-col w-full justify-center items-center shadow-sm">
        <div className="text-4xl font-bold text-white">Service Manager</div>
        <div className="text-white">Manage and record services.</div>
      </div>
      <div className="border rounded-md shadow-sm w-4/5 md:w-2/3">
        <div className="border-b p-4 text-lg">User History</div>
        <div className="flex-1 flex flex-col items-center p-4 gap-3">
          <select
            className="w-full outline-none border rounded-md p-2 shadow-sm"
            onChange={(e) => {
              const user = users?.find(
                (user) => user.id === parseInt(e.target.value)
              );
              setSelectedUser(user);
            }}
          >
            <option value={""}>Select a User</option>
            {users?.map((user) => {
              return (
                <option
                  value={user.id}
                  key={user.id}
                >{`${user.first_name} ${user.last_name}`}</option>
              );
            })}
          </select>
          <div className="w-full flex items-center justify-center border rounded p-3 shadow-sm">
            <table className="w-full">
              <thead>
                <tr>
                  <th className="text-left">Date</th>
                  <th className="text-left">Type</th>
                  <th className="text-left">Description</th>
                  <th className="text-left">Value</th>
                </tr>
              </thead>
              <tbody>
                {userHistory?.map((record: UserHistoryType) => {
                  return (
                    <tr>
                      <td>{record.date}</td>
                      <td>Service</td>
                      <td className="flex flex-col gap-1">
                        <div>
                          {
                            // Returning the service type that matches the id in the user history
                            serviceTypes?.find(
                              (serviceType) =>
                                serviceType.id === record.service_type
                            )?.name
                          }
                        </div>
                        {record.activity ? (
                          <div className="text-sm text-gray-400">
                            Activity:{" "}
                            {
                              activities?.find(
                                (activity) => activity.id === record.activity
                              )?.name
                            }
                          </div>
                        ) : null}
                        {record.staff_member ? (
                          <div className="text-sm text-gray-400">
                            Staff Member: {getStaffName(record)}
                          </div>
                        ) : null}
                        {record.notes ? (
                          <div className="text-sm text-gray-400">
                            Notes: {record.notes}
                          </div>
                        ) : null}
                      </td>
                      <td>N/A</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <GrayButton
        onClick={() => {
          setServiceCreator(true);
        }}
      >
        Add Service
      </GrayButton>
      {serviceCreator === true ? (
        <div className="w-full h-full fixed flex flex-col items-center justify-center bg-gray-800 bg-opacity-70 z-20 top-0 p-5">
          <div className="bg-white rounded-md shadow-sm w-2/3 opacity-100 overflow-y-scroll p-6 gap-4 flex flex-col items-center">
            <div className="w-full flex flex-row justify-between">
              <div className="text-xl">Add a Service</div>
              <button
                className="text-red-400"
                onClick={() => {
                  setServiceCreator(false);
                }}
              >
                Close
              </button>
            </div>
            <div className="w-full">
              <div className="font-bold">User:</div>
              <select
                className="w-full outline-none border rounded-md p-2 shadow-sm"
                onChange={(e) => {
                  const user = users?.find(
                    (user) => user.id === parseInt(e.target.value)
                  );
                  setSelectedUser(user);
                }}
              >
                <option value={""}>Select a User</option>
                {users?.map((user) => {
                  return (
                    <option
                      value={user.id}
                      key={user.id}
                    >{`${user.first_name} ${user.last_name}`}</option>
                  );
                })}
              </select>
            </div>
            <div className="w-full">
              <div className="font-bold">Service:</div>
              <select
                className="w-full outline-none border rounded-md p-2 shadow-sm"
                onChange={(e) => {
                  const service = serviceTypes?.find(
                    (s) => s.id === parseInt(e.target.value)
                  );
                  setSelectedServiceType(service);
                }}
              >
                <option value={""}>Select a Service</option>
                {serviceTypes?.map((service) => {
                  return (
                    <option value={service.id} key={service.id}>
                      {`${service.name}`}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="w-full">
              <div className="font-bold">Date:</div>
              <input
                type="date"
                className="w-full outline-none border rounded-md p-2 shadow-sm"
                value={serviceDate}
                onChange={(e) => {
                  setServiceDate(e.target.value.toString());
                }}
              />
            </div>
            <div className="w-full">
              <div className="font-bold">
                Duration in minutes:{" "}
                <span className="text-gray-400 text-sm">Optional</span>
              </div>
              <input
                type="number"
                className="w-full outline-none border rounded-md p-2 shadow-sm"
                value={duration}
                onChange={(e) => {
                  setDuration(parseInt(e.target.value));
                }}
              />
            </div>
            <div className="w-full">
              <div className="font-bold">
                Notes: <span className="text-gray-400 text-sm">Optional</span>
              </div>
              <textarea
                className="w-full outline-none border rounded-md p-2 shadow-sm"
                value={notes}
                onChange={(e) => {
                  setNotes(e.target.value);
                }}
              />
              <span className="text-sm text-gray-300">
                Notes are only visible to staff members.
              </span>
            </div>
            <div className="w-full">
              <div className="font-bold">
                Staff Member:{" "}
                <span className="text-gray-400 text-sm">Optional</span>
              </div>
              <select
                className="w-full outline-none border rounded-md p-2 shadow-sm"
                onChange={(e) => {
                  const staff = staffMembers?.find(
                    (s) => s.id === parseInt(e.target.value)
                  );
                  setSelectedStaffMember(staff);
                }}
              >
                <option value={""}>Select a Staff Member</option>
                {staffMembers?.map((staff_member) => {
                  return (
                    <option value={staff_member.id} key={staff_member.id}>
                      {`${staff_member.first_name} ${staff_member.last_name}`}
                    </option>
                  );
                })}
              </select>
              <span className="text-sm text-gray-300">
                The person who provided the service.
              </span>
            </div>
            <div className="w-full">
              <div className="font-bold">
                Activity:{" "}
                <span className="text-gray-400 text-sm">Optional</span>
              </div>
              <select
                className="w-full outline-none border rounded-md p-2 shadow-sm"
                onChange={(e) => {
                  const activity = activities?.find(
                    (s) => s.id === parseInt(e.target.value)
                  );
                  setSelectedActivity(activity);
                }}
              >
                <option value={""}>Select an Activity</option>
                {activities?.map((activity) => {
                  return (
                    <option value={activity.id} key={activity.id}>
                      {`${activity.name}`}
                    </option>
                  );
                })}
              </select>
              <span className="text-sm text-gray-300">
                Required if the service needs to be reported in the Program
                Delivery report.
              </span>
            </div>
            <GrayButton
              disabled={!complete}
              onClick={() => {
                addService();
                setServiceCreator(false);
              }}
            >
              Save
            </GrayButton>
          </div>
        </div>
      ) : null}
    </div>
  );
}
