import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./screens/Login";
import { routes } from "./util/routes";
import PrivateRoute from "./components/PrivateRoute";
import { hasJWT } from "./util/hasJWT";
import { setAuthHeader } from "./services/authServices";
import Dashboard from "./screens/Dashboard";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      console.log("Set auth header.");
      setAuthHeader(token);
    }
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Login />} path={routes.loginRoute} />
        <Route
          element={
            <PrivateRoute
              accessFunction={hasJWT}
              deniedRoute={routes.loginRoute}
            >
              <Dashboard />
            </PrivateRoute>
          }
          path={"/dashboard/*"}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
