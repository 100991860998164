import { EventType } from "../../types";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API_URL } from "../../util/routes";
import moment from "moment";
import GrayButton from "../GrayButton";

export default function EventView() {
  const [eventInfo, setEventInfo] = useState<EventType>();
  const [canRegister, setCanRegister] = useState<boolean>(true);
  const params = useParams();
  useEffect(() => {
    async function getEventInfo() {
      const response = await axios.get(
        `${API_URL}/events/event/${params.event_id}`
      );
      setEventInfo(response.data);
      if (moment().isAfter(eventInfo?.regristraion_deadline)) {
        setCanRegister(false);
      }
    }
    async function getRegistered() {
      // Checking if the user has already registered for the event
      const response = await axios.get(`${API_URL}/profiles/me/events`);
      const { data } = response;
      console.log(data);
      for (let i = 0; i < data.length; i++) {
        if (data[i].event.toString() === params.event_id) {
          setCanRegister(false);
          console.log("registered");
          break;
        }
      }
    }
    getEventInfo();
    getRegistered();
  }, [eventInfo?.regristraion_deadline, params.event_id]);

  async function register() {
    try {
      const response = await axios.post(`${API_URL}/events/registration/`, {
        event: params.event_id,
      });
      console.log(response.data);
      setCanRegister(false);
    } catch {
      console.log("Aready registered");
    }
  }
  return (
    <div className="flex flex-col p-6 items-center justify-center">
      <div className="w-4/5 border shadow rounded m-auto p-4 flex flex-col gap-4">
        <div className="text-xl">{eventInfo?.title}</div>
        <div>
          <div className="font-bold">Date/Time</div>
          <div>
            {moment(eventInfo?.start).format("YYYY-MM-DD hh:mm A")} -{" "}
            {moment(eventInfo?.end).format("hh:mm A")}
          </div>
        </div>
        <div>
          <div className="font-bold">Event Type</div>
          <div>{eventInfo?.location_type}</div>
        </div>
        <div>
          <div className="font-bold">Location</div>
          <div>{eventInfo?.location}</div>
        </div>
        <div>
          <div className="font-bold">Category</div>
          <div>{eventInfo?.event_category}</div>
        </div>
        <div>
          <div className="font-bold">Description</div>
          <div>{eventInfo?.description}</div>
        </div>
        <div>
          {eventInfo?.num_registered}/{eventInfo?.capacity} spots filled.
        </div>
        <GrayButton disabled={!canRegister} onClick={register}>
          Register
        </GrayButton>
      </div>
    </div>
  );
}
