export default function DeleteButton({
  onClick,
  children,
  extraStyles,
}: {
  onClick?: (x: any) => any;
  children: any;
  extraStyles?: string;
}) {
  return (
    <button
      onClick={onClick}
      className={`text-red-600 text-sm p-1 mr-4 border rounded-md border-red-400 ${extraStyles}`}
    >
      {children}
    </button>
  );
}
