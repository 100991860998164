import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { DiscussionType } from "../../types";
import { API_URL } from "../../util/routes";

const DiscussionButton = ({ discussion }: { discussion: DiscussionType }) => {
  const params = useParams();
  const [authorName, setAuthorName] = useState<string>();
  useEffect(() => {
    async function getAuthor() {
      const response = await axios.get(
        `${API_URL}/profiles/profile/${discussion.author}`
      );
      setAuthorName(`${response.data.first_name} ${response.data.last_name}`);
    }
    getAuthor();
  }, [discussion.author]);
  return (
    <button className="border rounded-md p-4 w-full text-left shadow-sm">
      <Link
        to={`/dashboard/groups/${params.group_id}/forum/${params.forum_id}/discussion/${discussion.id}`}
      >
        <div className="text-lg">{discussion.title}</div>
        <div className="text-gray-500 text-sm">
          Posted by {authorName} {moment(discussion.date).fromNow()}
        </div>
      </Link>
    </button>
  );
};

export default DiscussionButton;
