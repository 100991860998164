export default function GroupDescription({
  description,
}: {
  description: string | undefined;
}) {
  return (
    <div className="p-7 w-5/6 md:w-3/5 border rounded-md shadow-sm">
      <div>{description}</div>
    </div>
  );
}
