export default function GrayButton({
  onClick,
  children,
  extraStyles,
  disabled,
}: {
  onClick?: (x: any) => any;
  children: any;
  extraStyles?: string;
  disabled?: boolean;
}) {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={`flex flex-row items-center gap-1 text-sm border rounded-md shadow-sm p-3 disabled:bg-gray-100 ${extraStyles}`}
    >
      {children}
    </button>
  );
}
