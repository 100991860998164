import { ProfileSectionType } from "../../types";

export default function ProfileSection({
  profileSection,
  sectionData,
}: {
  profileSection: ProfileSectionType;
  sectionData: any | null;
}) {
  return (
    <div className="border rounded-md shadow-sm w-full flex flex-col">
      <div className="text-lg w-full-b p-3 font-semibold">
        {profileSection.name}
      </div>
      {sectionData
        ? profileSection.fields.map((field) => {
            let data = sectionData[field.db_name];
            if (data !== null) {
              console.log(data);
              if (typeof data === "boolean") {
                data = data ? "Yes" : "No";
              }
              return (
                <div className="flex flex-row border-t" key={field.db_name}>
                  <div className="w-1/2 border-r p-3">{field.name}</div>
                  <div className="p-3 flex-1">{data}</div>
                </div>
              );
            }
            return null;
          })
        : null}
    </div>
  );
}
