import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { login } from "../services/authServices";
import { hasJWT } from "../util/hasJWT";

function Login() {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [authenticated, setAuthenticated] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    console.log("Check authenticated");
    if (hasJWT()) {
      setAuthenticated(true);
    }
    if (authenticated) {
      navigate("/dashboard");
    }
  }, [authenticated, navigate]);

  return (
    <div className="w-screen h-screen flex justify-center items-center">
      <div className="flex flex-col gap-5">
        <div className="text-2xl font-semibold">Login</div>
        <input
          className="text-lg"
          placeholder="Email"
          type="email"
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
        <input
          type="password"
          className="text-lg"
          placeholder="Password"
          autoComplete="on"
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
        <button
          onClick={async () => {
            const loggedin = await login(email, password);
            setAuthenticated(loggedin);
          }}
          className="border"
        >
          Login
        </button>
      </div>
    </div>
  );
}

export default Login;
