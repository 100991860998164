import { ProfileSectionType } from "../types";

export const status: ProfileSectionType = {
  name: "Status",
  db_name: "Status",
  fields: [
    {
      name: "What is your professional status with respect to AIMGA?",
      db_name: "status_with_respect_to_aimga",
      subtitle:
        "If you are an IMG or CSA then you will be able to enter additional profile information such as your medical training, employment history, career goals, and so on",
      type: "multiple-choice",
      options: [
        "International Medical Graduate (IMG)",
        "Canadian who Studied Abroad (CSA)",
        "Affiliate Orginization",
        "Other",
      ],
      required: true,
    },
    {
      name: "How did you hear about us?",
      subtitle: "Check all that apply",
      db_name: "how_did_you_hear",
      type: "multiple-select",
      options: [
        "Word of mouth",
        "Website search",
        "Alberta International Medical Graduate Program (AIMGP)",
        "Immigrant serving organization",
        "College of Physicians and Surgeons of Alberta (CPSA)",
        "Medical Council of Canada (MCC)",
        "Alberta Medical Association (AMA)",
        "Other",
      ],
    },
  ],
};

export const social: ProfileSectionType = {
  name: "Social",
  db_name: "Social",
  fields: [
    {
      name: "Biography",
      db_name: "biography",
      type: "textarea",
    },
    {
      name: "LinkedIn",
      db_name: "linkedin",
      type: "text",
    },
    {
      name: "Twitter",
      db_name: "twitter",
      type: "text",
    },
    {
      name: "Facebook",
      db_name: "facebook",
      type: "text",
    },
    {
      name: "Instagram",
      db_name: "instagram",
      type: "text",
    },
    {
      name: "Skype",
      db_name: "skype",
      type: "text",
    },
  ],
};

export const background: ProfileSectionType = {
  name: "Background",
  db_name: "Background",
  fields: [
    {
      name: "Date of Birth",
      type: "date",
      db_name: "date_of_birth",
      required: true,
      subtitle: "This is required for AIMGA funding and reporting services",
    },
    {
      name: "Gender",
      type: "text",
      db_name: "gender",
      required: true,
    },
    {
      name: "Country of Birth",
      type: "text",
      db_name: "country_of_birth",
      required: true,
    },
    {
      name: "What is your first langauge?",
      type: "text",
      db_name: "first_language",
      required: true,
    },
    {
      name: "Please specify any additional language in which you are fluent",
      type: "text",
      db_name: "additional_language_1 ",
    },
    {
      name: "Please specify any second additional language in which you are fluent",
      type: "text",
      db_name: "additional_language_2 ",
    },
    {
      name: "Please specify any third additional language in which you are fluent",
      type: "text",
      db_name: "additional_language_3 ",
    },
  ],
};

export const citizenship: ProfileSectionType = {
  name: "Citizenship",
  db_name: "Citizenship",
  fields: [
    {
      name: "Were you born in Canada?",
      db_name: "born_in_canada",
      type: "boolean",
      required: true,
    },
    {
      name: "What is your current status in Canada?",
      db_name: "current_status_in_canada",
      type: "multiple-choice",
      options: [
        "Permanent resident",
        "Refugee",
        "Temporary resident (e.g. student or working visa)",
        "Canadian citizen",
        "Other (e.g. visitor or international resident)",
      ],
      required: true,
    },
    {
      name: "Please enter your Permanent Resident number",
      subtitle:
        "We are required to collect this identifier if you would like to to participate in IRCC-funded programs and services",
      db_name: "permanent_resident_number",
      type: "text",
      display_condition: ["current_status_in_canada", "Permanent resident"],
    },
    {
      name: "Please enter your Refugee Claim number",
      subtitle:
        "We are required to collect this identifier if you would like to to participate in IRCC-funded programs and services",
      db_name: "refugee_claim_number",
      type: "text",
      display_condition: ["current_status_in_canada", "Refugee"],
    },
    {
      name: "Please enter your IMM number",
      subtitle:
        "We are required to collect this identifier if you would like to to participate in IRCC-funded programs and services",
      db_name: "imm_number",
      type: "text",
      display_condition: [
        "current_status_in_canada",
        "Temporary resident (e.g. student or working visa)",
      ],
    },
  ],
};

export const education: ProfileSectionType = {
  name: "Education",
  db_name: "Education",
  fields: [
    {
      name: "Do you have a medical degree from a school listed in the International Medical Education directory?",
      required: true,
      db_name: "medical_degree_imed",
      type: "boolean",
    },
    {
      name: "Post-graduate: Have you completed your post-graduate medical training?",
      required: true,
      db_name: "post_grad_medical_training",
      type: "boolean",
    },
    {
      name: "Post-graduate: Is your training program recognized by the College of Family Physicians of Canada?",
      db_name: "post_grad_recognized",
      type: "boolean",
      display_condition: ["post_grad_medical_training", "true"],
    },
    {
      name: "Post-graduate: University of affiliation",
      db_name: "post_grad_university",
      type: "text",
      display_condition: ["post_grad_medical_training", "true"],
    },
    {
      name: "Post-graduate: Language of medical training",
      db_name: "post_grad_language",
      type: "text",
      display_condition: ["post_grad_medical_training", "true"],
    },
    {
      name: "Post-graduate: Length of training (in years)",
      db_name: "post_grad_length",
      type: "multiple-choice",
      options: ["Less than 1 year", "1", "2", "3", "4", "5 years or more"],
      display_condition: ["post_grad_medical_training", "true"],
    },
    {
      name: "Post-graduate: Year of completion",
      db_name: "post_grad_year_completion",
      type: "text",
      display_condition: ["post_grad_medical_training", "true"],
    },
    {
      name: "Residency: Have you completed your residency training?",
      db_name: "completed_residency",
      type: "boolean",
      required: true,
    },
    {
      name: "Residency: Community-based primary care training?",
      db_name: "residency_primary_care",
      subtitle:
        "Please indicate the length of training you had in this area during your post-graduate training (i.e. residency)",
      type: "multiple-choice",
      options: ["None", "Less than 4 months", "4 months", "More than 4 months"],
      display_condition: ["completed_residency", "true"],
    },
    {
      name: "Residency: Pediatrics",
      db_name: "residency_pediatrics",
      subtitle:
        "Please indicate the length of training you had in this area during your post-graduate training (i.e. residency)",
      type: "multiple-choice",
      options: ["None", "Less than 4 months", "4 months", "More than 4 months"],
      display_condition: ["completed_residency", "true"],
    },
    {
      name: "Residency: Obstetrics / Gynecology",
      db_name: "residency_obstetrics",
      subtitle:
        "Please indicate the length of training you had in this area during your post-graduate training (i.e. residency)",
      type: "multiple-choice",
      options: ["None", "Less than 4 months", "4 months", "More than 4 months"],
      display_condition: ["completed_residency", "true"],
    },
    {
      name: "Residency: Surgery",
      db_name: "residency_surgery",
      subtitle:
        "Please indicate the length of training you had in this area during your post-graduate training (i.e. residency)",
      type: "multiple-choice",
      options: ["None", "Less than 4 months", "4 months", "More than 4 months"],
      display_condition: ["completed_residency", "true"],
    },
    {
      name: "Residency: Internal Medicine",
      db_name: "residency_internal_med",
      subtitle:
        "Please indicate the length of training you had in this area during your post-graduate training (i.e. residency)",
      type: "multiple-choice",
      options: ["None", "Less than 4 months", "4 months", "More than 4 months"],
      display_condition: ["completed_residency", "true"],
    },
    {
      name: "Residency: Emergency Medicine",
      db_name: "residency_emergency_med",
      subtitle:
        "Please indicate the length of training you had in this area during your post-graduate training (i.e. residency)",
      type: "multiple-choice",
      options: ["None", "Less than 4 months", "4 months", "More than 4 months"],
      display_condition: ["completed_residency", "true"],
    },
    {
      name: "Residency: Psychiatry",
      db_name: "residency_psychiatry",
      subtitle:
        "Please indicate the length of training you had in this area during your post-graduate training (i.e. residency)",
      type: "multiple-choice",
      options: ["None", "Less than 4 months", "4 months", "More than 4 months"],
      display_condition: ["completed_residency", "true"],
    },
    {
      name: "Speciality: Have you completed any specialty training?",
      db_name: "any_specialty_training",
      type: "boolean",
    },
    {
      name: "Specialty: University of affiliation",
      db_name: "specialty_university",
      type: "text",
      display_condition: ["any_specialty_training", "true"],
    },
    {
      name: "Specialty: Language of medical training",
      db_name: "specialty_language",
      type: "text",
      display_condition: ["any_specialty_training", "true"],
    },
    {
      name: "Specialty: Length of training (in years)",
      db_name: "specialty_length",
      type: "multiple-choice",
      options: [
        "Less than 1 year",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "More than 8 years",
      ],
      display_condition: ["any_specialty_training", "true"],
    },
    {
      name: "Specialty: Area of specialization",
      db_name: "specialty_area",
      type: "text",
      display_condition: ["any_specialty_training", "true"],
    },
    {
      name: "Specialty: Year of completion",
      db_name: "specialty_year_completion",
      type: "text",
      display_condition: ["any_specialty_training", "true"],
    },
  ],
};

export const english_exams: ProfileSectionType = {
  name: "English Exams",
  db_name: "EnglishExams",
  fields: [
    {
      name: "IELTS: Have you taken the International English Language Testing System exam?",
      required: true,
      db_name: "ielts",
      type: "multiple-choice",
      options: [
        "I do not need to take this exam",
        "I am planning to take and/or am studying for the exam",
        "I have taken this exam",
      ],
    },
    {
      name: "IELTS: Have you ever passed this exam?",
      db_name: "ielts_passed", // FINISH LATER
      subtitle:
        "This means that you have achieved a score of 7 or above in all skill areas",
      type: "boolean",
      display_condition: ["ielts", "I have taken this exam"],
    },
    {
      name: "IELTS: How many times have you taken this exam?",
      db_name: "ielts_attempts", // FINISH LATER
      type: "multiple-choice",
      options: [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "10 or more",
      ],
      display_condition: ["ielts", "I have taken this exam"],
    },
    {
      name: "IELTS: What was the most recent year you took this exam?",
      db_name: "ielts_most_recent_year", // FINISH LATER
      type: "text",
      display_condition: ["ielts", "I have taken this exam"],
    },
    {
      name: "IELTS: What was your most recent overall band score?",
      db_name: "ielts_band_score", // FINISH LATER
      type: "text",
      display_condition: ["ielts", "I have taken this exam"],
    },
    {
      name: "IELTS: What was your most recent reading score?",
      db_name: "ielts_reading_score", // FINISH LATER
      type: "text",
      display_condition: ["ielts", "I have taken this exam"],
    },
    {
      name: "IELTS: What was your most recent listening score?",
      db_name: "ielts_listening_score", // FINISH LATER
      type: "text",
      display_condition: ["ielts", "I have taken this exam"],
    },
    {
      name: "IELTS: What was your most recent speaking score?",
      db_name: "ielts_speaking_score", // FINISH LATER
      type: "text",
      display_condition: ["ielts", "I have taken this exam"],
    },
    {
      name: "IELTS: What was your most recent writing score?",
      db_name: "ielts_writing_score", // FINISH LATER
      type: "text",
      display_condition: ["ielts", "I have taken this exam"],
    },
    {
      name: "Have you taken any of the following exams?",
      subtitle: "Please check all that apply",
      db_name: "following_exams", // FINISH LATER
      type: "multiple-select",
      options: ["iBT", "OET", "CLBA"],
    },
  ],
};

export const medical_exams: ProfileSectionType = {
  name: "Medical Exams",
  db_name: "MedicalExams",
  fields: [
    {
      name: "MCCQE1: Have you taken the Medical Council of Canada Qualifying Exam Part 1?",
      required: true,
      db_name: "mccq_exam1",
      type: "multiple-choice",
      options: [
        "I do not need to take this exam",
        "I am planning to take and/or am studying for the exam",
        "I have taken this exam",
      ],
    },
    {
      name: "MCCQE1: Have you passed this exam?",
      db_name: "mccq_exam1_passed",
      type: "boolean",
      display_condition: ["mccq_exam1", "I have taken this exam"],
    },
    {
      name: "MCCQE1: What was the most recent year you took this exam?",
      db_name: "mccq_exam1_recent_year",
      type: "text",
      display_condition: ["mccq_exam1", "I have taken this exam"],
    },
    {
      name: "MCCQE1: What was your most recent score?",
      db_name: "mccq_exam1_recent_score",
      type: "text",
      display_condition: ["mccq_exam1", "I have taken this exam"],
    },
    {
      name: "MCCQE2: Have you taken the Medical Council of Canada Qualifying Exam Part 2?",
      required: true,
      db_name: "mccq_exam2",
      type: "multiple-choice",
      options: [
        "I do not need to take this exam",
        "I am planning to take and/or am studying for the exam",
        "I have taken this exam",
      ],
    },
    {
      name: "MCCQE2: Have you passed this exam?",
      db_name: "mccq_exam2_passed",
      type: "boolean",
      display_condition: ["mccq_exam2", "I have taken this exam"],
    },
    {
      name: "MCCQE2: What was the most recent year you took this exam?",
      db_name: "mcqq_exam2_recent_year",
      type: "text",
      display_condition: ["mccq_exam2", "I have taken this exam"],
    },
    {
      name: "MCCQE2: What was your most recent score?",
      db_name: "mccq_exam2_recent_score",
      type: "text",
      display_condition: ["mccq_exam2", "I have taken this exam"],
    },
    {
      name: "NAC OSCE: Have you taken the NAC OSCE?",
      required: true,
      db_name: "nac_osce",
      type: "multiple-choice",
      subtitle:
        "This is the National Assessment Collaboration (NAC) Objective Structured Clinical Examination (OSCE)",
      options: [
        "I do not need to take this exam",
        "I am planning to take and/or am studying for the exam",
        "I have taken this exam",
      ],
    },
    {
      name: "NAC OSCE: How many times have you taken this exam?",
      db_name: "nac_osce_attempts",
      type: "multiple-choice",
      options: ["1", "2", "3", "4", "5 or more"],
      display_condition: ["nac_osce", "I have taken this exam"],
    },
    {
      name: "NAC OSCE: What was the most recent year you took this exam?",
      db_name: "nac_osce_recent_year",
      type: "text",
      display_condition: ["nac_osce", "I have taken this exam"],
    },
    {
      name: "NAC OSCE: What were your test results (for tests in 2020 and later)?",
      db_name: "nac_osce_results",
      type: "multiple-choice",
      options: ["Superior Pass", "Pass", "Fail"],
      display_condition: ["nac_osce", "I have taken this exam"],
    },

    {
      name: "NAC OSCE: What was your most recent score (for tests prior to 2020)?",
      db_name: "nac_osce_results_prior",
      type: "text",
      display_condition: ["nac_osce", "I have taken this exam"],
    },
    {
      name: "Which additional Canadian qualifying medical exams have you completed?",
      subtitle: "Check all that apply",
      db_name: "additional_completed_candian_exams",
      type: "multiple-select",
      options: ["CCFP", "CCFP (EM)", "FRCP (C)", "FRCS (C)", "Other"],
    },
    {
      name: "Which additional US qualifying medical exams have you completed?",
      subtitle: "Check all that apply",
      db_name: "additional_completed_us_exams",
      type: "multiple-select",
      options: ["USLME Step 1", "USLME Step 2", "Other"],
    },
  ],
};

export const current_employment: ProfileSectionType = {
  name: "Current Employment",
  db_name: "CurrentEmployment",
  fields: [
    {
      name: "What is your current employment status?",
      required: true,
      db_name: "employment_status",
      type: "multiple-choice",
      options: [
        "Unemployed, seeking work",
        "Unemployed, not seeking work",
        "Part-time position",
        "Full-time position",
        "Student",
      ],
    },
    {
      name: "If you are working, in what field are you currently working? (required)?",
      required: true,
      db_name: "field",
      type: "multiple-choice",
      options: [
        "Physician (in Canada)",
        "Physician (outside Canada)",
        "Medical position other than physician",
        "Non-medical position",
        "N/A",
      ],
    },
    {
      name: "If you are working, what is your hourly rate?",
      subtitle:
        "This is an optional question, but we are asking so we can gain information to highlight the issue of underemployment among IMGs in Canada.",
      db_name: "hourly_rate",
      type: "text",
    },
    {
      name: "Please specify the settings in which you would like to work",
      subtitle: "Check all that apply",
      db_name: "work_setting",
      type: "multiple-select",
      options: ["Urban", "Rural"],
    },
    {
      name: "Are you interested in pursuing alternative (non-physician) careers?",
      db_name: "other_carreer_interest",
      type: "boolean",
    },
    {
      name: "What other alternative career interests you?",
      db_name: "alternative_other_carreer",
      type: "text",
    },
  ],
};

export const work_history: ProfileSectionType = {
  name: "Work History",
  db_name: "WorkHistory",
  fields: [
    {
      name: "Primary speciality",
      db_name: "specialty",
      required: true,
      type: "multiple-choice",
      options: [
        "Anaesthesia",
        "Cardiology or cardiac surgery",
        "Dermatology",
        "Emergency medicine",
        "Family or general medicine",
        "Gastroenterology",
        "Internal medicine",
        "Neurology",
        "Neurosurgery",
        "Obstetrics and Gynecology",
        "Ophthalmology",
        "Oral and Maxillofacial Surgery",
        "Orthopedic surgery",
        "Otolaryngology",
        "Pediatrics",
        "Plastic surgery",
        "Podiatry",
        "Psychiatry",
        "Public or community health",
        "Pulmonology",
        "Radiology",
        "Surgery",
        "Urology",
        "Other",
      ],
    },
    {
      name: "Secondary speciality (if any)",
      db_name: "secondary_specialty",
      type: "multiple-choice",
      options: [
        "Anaesthesia",
        "Cardiology or cardiac surgery",
        "Dermatology",
        "Emergency medicine",
        "Family or general medicine",
        "Gastroenterology",
        "Internal medicine",
        "Neurology",
        "Neurosurgery",
        "Obstetrics and Gynecology",
        "Ophthalmology",
        "Oral and Maxillofacial Surgery",
        "Orthopedic surgery",
        "Otolaryngology",
        "Pediatrics",
        "Plastic surgery",
        "Podiatry",
        "Psychiatry",
        "Public or community health",
        "Pulmonology",
        "Radiology",
        "Surgery",
        "Urology",
        "Other",
      ],
    },
    {
      name: "What is your total number of years in practice?",
      db_name: "years_in_practice",
      required: true,
      type: "multiple-choice",
      options: [
        "1 or less",
        "2-3 years",
        "4-5 years",
        "6-7 years",
        "8-9 years",
        "10 or more years",
      ],
      subtitle: "Do not include post-graduate and residency training",
    },
    {
      name: "Do you hold an active medical license in another country?",
      db_name: "medical_license_in_other_country",
      required: true,
      type: "boolean",
    },
    {
      name: "Please specify the first country in which you hold an active medical license",
      db_name: "first_other_country",
      type: "text",
      display_condition: ["medical_license_in_other_country", "true"],
    },
    {
      name: "Please specify the second country in which you hold an active medical license (if any)",
      db_name: "second_other_country",
      type: "text",
      display_condition: ["medical_license_in_other_country", "true"],
    },
    {
      name: "What is the total number of years that you have been out of practice?",
      db_name: "years_out_of_practice",
      required: true,
      type: "multiple-choice",
      options: [
        "1 or less",
        "2-3 years",
        "4-5 years",
        "6-7 years",
        "8-9 years",
        "10 or more years",
      ],
    },
    {
      name: "In which workplace settings have you worked?",
      db_name: "work_place_settings",
      type: "multiple-select",
      subtitle: "Check all that apply",
      options: [
        "Rural",
        "University",
        "Hospital",
        "Clinic",
        "Urban",
        "Military",
        "Other",
      ],
    },
  ],
};

export const licensure: ProfileSectionType = {
  name: "Licensure",
  db_name: "Licensure",
  fields: [
    {
      name: "Where are you on the pathway to licensure?",
      db_name: "where_in_path_to_licensure",
      type: "multiple-choice",
      options: [
        "I do not have plans to seek licensure, and did not start the process",
        "I plan to seek licensure, but I have not yet started the process",
        "I have started the process, but it is not yet complete",
        "I started the process, but have decided not to continue",
        "I am fully licensed",
      ],
      required: true,
    },
    {
      name: "Do you have an account with physiciansapply.ca? (required)",
      db_name: "account_with_physicians_apply",
      type: "multiple-choice",
      options: [
        "I do not need this account",
        "I have an account with physiciansapply.ca and I am awaiting source verification of my credentials",
        "I have an account with physiciansapply.ca and my credentials have been verified",
      ],
      required: true,
    },
    {
      name: "At any point, did you consider the independent practice or direct transfer pathways to licensure?",
      db_name: "considered_independent_practice",
      type: "boolean",
      required: true,
    },
    {
      name: "Which of the following steps of the independent practice or direct transfer pathway apply to you?",
      db_name: "which_of_the_following_independent_practice",
      subtitle: "Check all that apply",
      type: "multiple-select",
      options: [
        "I did not meet the criteria to apply for an independent practice permit through the CPSA",
        "I applied for an independent practice permit through the CPSA (or other provincial licensing body)",
        "I received my independent my practice permit",
        "I sought (or am seeking) an AHS sponsorship eligible position",
        "I could not find an AHS sponsorship eligible position",
        "I obtained an AHS sponsorship eligible position",
        "I completed (or am taking) the Practice Readiness Assessment (PRA)",
        "I received a partial license",
        "I worked (or am working) under the supervision of another physician",
        "I am preparing for the TDM exam",
        "I have passed the TDM exam",
      ],
      display_condition: ["considered_independent_practice", "true"],
    },
    {
      name: "At any point, did you consider the residency pathway to licensure?",
      db_name: "considered_residency_pathway",
      type: "boolean",
      required: true,
    },
    {
      name: "Which of the following steps of the residency pathway apply to you?",
      db_name: "which_of_the_following_residency",
      subtitle: "Check all that apply",
      type: "multiple-select",
      options: [
        "I did not meet the requirements for residency",
        "I met the requirements for residency",
        "I sought (or am seeking) a residency position",
        "I participated in residency interviews",
        "I started residency",
        "I completed residency",
        "I am taking (or have taken) final examinations (e.g. CCFP, CCFP-EM)",
      ],
      display_condition: ["considered_residency_pathway", "true"],
    },
    {
      name: "Have you applied for a Canadian residency position?",
      db_name: "applied_for_residency_position",
      type: "boolean",
      display_condition: ["considered_residency_pathway", "true"],
    },
  ],
};

export const contact: ProfileSectionType = {
  name: "Contact Info",
  db_name: "Contact",
  fields: [
    { name: "First Name", db_name: "first_name", type: "text", required: true },
    { name: "Last Name", db_name: "last_name", type: "text", required: true },
    {
      name: "Street Address",
      db_name: "street_address",
      type: "text",
      required: true,
    },
    { name: "City", db_name: "city", type: "text", required: true },
    {
      name: "Province",
      db_name: "province",
      type: "multiple-choice",
      required: true,
      options: [
        "AB",
        "BC",
        "MB",
        "NB",
        "NL",
        "NS",
        "NT",
        "NU",
        "ON",
        "PE",
        "QC",
        "SK",
        "YT",
        "Outside Canada",
      ],
    },
    {
      name: "Postal Code",
      db_name: "postal_code",
      type: "text",
      required: true,
    },
    { name: "Phone Number", db_name: "phone", type: "text", required: true },
  ],
};

export const needs_assessment: ProfileSectionType = {
  name: "Needs Assessment",
  db_name: "NeedsAssessment",
  fields: [
    {
      name: "Advisor Name",
      db_name: "advisor_name",
      type: "text",
    },
    {
      name: "Date of Needs Assessment",
      db_name: "date_of_needs_assessment",
      type: "date",
    },
    {
      name: "Summary of Discussion",
      db_name: "discussion_summary",
      type: "textarea",
    },
    {
      name: "Short-Term Goals",
      subtitle:
        "SMART - Specific, Measurable, Attainable, Relevant, and Time-bound",
      db_name: "short_term_goals",
      type: "textarea",
    },
    {
      name: "Intermediate Goals",
      subtitle:
        "SMART - Specific, Measurable, Attainable, Relevant, and Time-bound",
      db_name: "intermediate_goals",
      type: "textarea",
    },
    {
      name: "Long-Term Goals",
      subtitle:
        "SMART - Specific, Measurable, Attainable, Relevant, and Time-bound",
      db_name: "long_term_goals",
      type: "textarea",
    },
    {
      name: "Recommendations: New Member Orientation",
      subtitle: "Please select the ways that AIMGA can support the member",
      db_name: "recommendations_new_member",
      type: "multiple-select",
      options: [
        "Part 1 (Intro to AIMGA)",
        "Part 2 (Licensure)",
        "Part 3 (Alternative Careers)",
      ],
    },
    {
      name: "Recommendations: Study Groups",
      subtitle: "Please select the ways that AIMGA can support the member",
      db_name: "recommendations_study_groups",
      type: "multiple-select",
      options: ["QE1", "TDM", "NAC OSCE"],
    },
    {
      name: "Recommendations: Workshop Series",
      subtitle: "Please select the ways that AIMGA can support the member",
      db_name: "recommendations_workshops",
      type: "multiple-select",
      options: ["ELP: IELTS/OET", "Employment Series", "CaRMS Series"],
    },
    {
      name: "Recommendations: Programs",
      subtitle: "Please select the ways that AIMGA can support the member",
      db_name: "recommendations_programs",
      type: "multiple-select",
      options: [
        "MCAP Online",
        "Career Transition Program",
        "Patient Navigator Program",
        "Mentorship Program",
      ],
    },
    {
      name: "Other Barriers and Challenges",
      subtitle: "Please select the ways that AIMGA can support the member",
      db_name: "other_barriers",
      type: "multiple-select",
      options: [
        "Housing (e.g. lack of stable living conditions or personal space)",
        "Economic (e.g. financial stress or difficulties making ends meet or providing for family)",
        "Social (e.g. lack of network, friends, or support; a feeling of isolation)",
        "Personal (e.g. physical health, mental health, relationship issues, dealing with an illness in the family, other)",
        "Feeling frustrated, angry, or anxious with regards to licensure and/or job opportunities",
        "Feeling down, worthless, or depressed with regards to licensure and/or job opportunities",
        "Professional (e.g. loss of professional identity and status within Canada)",
        "Cultural (e.g. feeling out of place within the Canadian culture or unfamiliarity with Canadian norms and culture)",
      ],
    },
    {
      name: "Referral Notes",
      subtitle:
        "Please enter any additional notes regarding the above barriers and challenges",
      db_name: "referral_notes",
      type: "textarea",
    },
    {
      name: "Next Steps",
      db_name: "next_steps",
      type: "textarea",
    },
    {
      name: "Follow Up Meeting: Year",
      db_name: "follow_up_meeting_year",
      subtitle:
        "Specify the tentative timeframe for a follow up meeting, if one is planned",
      type: "multiple-choice",
      options: ["2022", "2023", "2024", "2025", "2026"],
    },
    {
      name: "Follow Up Meeting: Month",
      db_name: "follow_up_meeting_month",
      subtitle:
        "Specify the tentative timeframe for a follow up meeting, if one is planned",
      type: "multiple-choice",
      options: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
    },
  ],
};

export const agreements: ProfileSectionType = {
  name: "Agreements",
  db_name: "Agreements",
  fields: [
    {
      name: "I agree to abide by the Terms of Use for this website",
      db_name: "terms_of_use",
      type: "multiple-select",
      options: ["Yes I agree"],
      required: true,
    },
    {
      name: "I grant permission for AIMGA to use my image for educational or promotional purposes",
      db_name: "image_agreement",
      subtitle:
        "From time to time AIMGA will take videos and photographs for educational or promotional purposes during AIMGA events, and during the delivery of our programs and services. Such images may be taken either at the AIMGA office or elsewhere. If you are not comfortable with a particular image being used in this manner we will honour reasonable requests to have it removed.",
      type: "multiple-select",
      options: ["Yes I agree"],
      required: true,
    },
    {
      name: "I acknowledge that AIMGA's activities are primarily funded by IRCC and some members may not be eligible to participate in certain programs",
      db_name: "ircc_funding_statement",
      subtitle:
        "From time to time AIMGA will take videos and photographs for educational or promotional purposes during AIMGA events, and during the delivery of our programs and services. Such images may be taken either at the AIMGA office or elsewhere. If you are not comfortable with a particular image being used in this manner we will honour reasonable requests to have it removed.",
      type: "multiple-select",
      options: ["Yes I agree"],
      required: true,
    },
    {
      name: "I declare that the information submitted in this profile is true, complete, and correct",
      db_name: "profile_complete_declaration",
      subtitle:
        "This declaration is required in order to be eligible to sign up for certain AIMGA-sponsored events.",
      type: "multiple-select",
      options: ["Yes I agree"],
    },
  ],
};

export const profileSections = [
  contact,
  status,
  social,
  background,
  citizenship,
  education,
  english_exams,
  medical_exams,
  current_employment,
  work_history,
  licensure,
  needs_assessment,
  agreements,
];
