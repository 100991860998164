import axios from "axios";
import { useEffect, useState } from "react";
import { API_URL } from "../../util/routes";
import { profileSections } from "../../util/profileSections";
import ProfileSection from "./ProfileSection";
import { Link, Route, Routes } from "react-router-dom";
import EditProfile from "./EditProfile";
import { ProfileSectionType } from "../../types";
import TabButton from "../TabButton";

export default function Profile() {
  const [sections, setSections] = useState<any>();
  const [page, setPage] = useState<"View Profile" | "Edit Profile">(
    "View Profile"
  );
  useEffect(() => {
    async function getSections() {
      const response = await axios.get(`${API_URL}/profiles/me/sections/`);
      setSections(response.data);
      console.log("got profile sections");
    }
    getSections();
  }, []);

  const availableSections: ProfileSectionType[] = [];

  function createProfileSections(sections: any) {
    const userProfileSections = [];
    for (let [key, value] of Object.entries(sections)) {
      console.log("key", key);
      const profileSection = profileSections.find((x) => x.db_name === key);
      if (profileSection) {
        console.log(profileSection);
        userProfileSections.push(
          <ProfileSection
            key={key}
            sectionData={value}
            profileSection={profileSection}
          />
        );
        availableSections.push(profileSection);
      }
    }
    return userProfileSections;
  }

  return (
    <div className="flex flex-col gap-3 items-center w-5/6">
      <div className="flex flex-row gap-2">
        <Link to="/dashboard/profile/profile/">
          <TabButton
            disabled={page === "View Profile"}
            onClick={() => {
              setPage("View Profile");
            }}
          >
            View Profile
          </TabButton>
        </Link>
        <Link to="edit/Contact">
          <TabButton
            disabled={page === "Edit Profile"}
            onClick={() => {
              setPage("Edit Profile");
            }}
          >
            Edit Profile
          </TabButton>
        </Link>
      </div>
      <Routes>
        <Route
          path="/"
          element={
            <div className="flex flex-col gap-3 items-center w-full">
              <div className="text-xl">View Profile</div>
              <div className="flex flex-col gap-3 items-center w-full">
                <div className="border rounded-md shadow-sm w-full flex flex-col">
                  <div className="text-lg w-full-b p-3 font-semibold">
                    Profile
                  </div>
                  <div className="flex flex-row border-t">
                    <div className="w-1/2 border-r p-3">Profile Completion</div>
                    <div className="p-3 flex-1">
                      {Math.floor(sections?.profile_completion)}%
                    </div>
                  </div>
                </div>
                {sections ? createProfileSections(sections) : null}
              </div>
            </div>
          }
        />
        <Route
          path="/edit/*"
          element={<EditProfile availableSections={availableSections} />}
        />
      </Routes>
    </div>
  );
}
