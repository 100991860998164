import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API_URL } from "../../util/routes";
import { profileSections } from "../../util/profileSections";
import { ProfileSectionType } from "../../types";
import GrayButton from "../GrayButton";

export default function ProfileEditor() {
  const [profileSection, setProfileSection] = useState<ProfileSectionType>();
  const [sectionData, setSectionData] = useState<any>();
  const [complete, setComplete] = useState<boolean>();
  const params = useParams();
  useEffect(() => {
    async function getData() {
      const response = await axios.get(
        `${API_URL}/profiles/me/sections/${params.section_name}`
      );
      console.log(response.data);
      setSectionData(response.data);
      console.log("Got section data");
      console.log("Get profile section");
      setProfileSection(
        profileSections.find((x) => x.db_name === params.section_name)
      );
    }
    getData();
  }, [params.section_name]);

  // Checking if all the required fields are filled
  useEffect(() => {
    let complete = true;
    profileSection?.fields.forEach((field) => {
      if (field.required) {
        if (
          sectionData[field.db_name] === "" ||
          sectionData[field.db_name] === undefined ||
          sectionData[field.db_name] === null
        ) {
          complete = false;
          return;
        }
      }
    });
    setComplete(complete);
  }, [profileSection?.fields, sectionData]);

  async function updateSection() {
    const response = await axios.put(
      `${API_URL}/profiles/me/sections/${params.section_name}/`,
      {
        ...sectionData,
      }
    );
    console.log(response.data);
    window.alert("Section Succesfully Updated.");
  }

  return (
    <div className="w-full flex flex-col gap-5 border rounded-md shadow-sm py-4 px-8">
      <div className="text-xl m-auto">Edit {profileSection?.name}</div>
      {profileSection
        ? profileSection.fields.map((field) => {
            if (field.display_condition) {
              if (sectionData[field.display_condition[0]] === undefined) {
                return null;
              }
              if (
                sectionData[field.display_condition[0]].toString() !==
                field.display_condition[1]
              ) {
                return null;
              }
            }
            switch (field.type) {
              case "text":
                return (
                  <div className="flex flex-col gap-2" key={field.db_name}>
                    <div className="text-lg font-semibold">
                      {field.name}{" "}
                      {field.required ? (
                        <span className="text-red-400">(Required)</span>
                      ) : null}
                    </div>
                    <input
                      className="w-full outline-none border rounded-md p-2 shadow-sm"
                      value={sectionData[field.db_name] || ""}
                      onChange={(e) => {
                        console.log("on change");
                        setSectionData({
                          ...sectionData,
                          [field.db_name]: e.target.value,
                        });
                      }}
                      type="text"
                    />
                    {field.subtitle ? (
                      <div className="text-sm text-gray-500">
                        {field.subtitle}
                      </div>
                    ) : null}
                  </div>
                );
              case "multiple-choice":
                return (
                  <div className="flex flex-col gap-2" key={field.db_name}>
                    <div className="text-lg font-semibold">
                      {field.name}{" "}
                      {field.required ? (
                        <span className="text-red-400">(Required)</span>
                      ) : null}
                    </div>
                    <select
                      className="w-full outline-none border rounded-md p-2 shadow-sm"
                      value={sectionData[field.db_name] || ""}
                      onChange={(e) => {
                        setSectionData({
                          ...sectionData,
                          [field.db_name]: e.target.value,
                        });
                      }}
                    >
                      <option value={""}>---</option>
                      {field.options?.map((option) => {
                        return (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        );
                      })}
                    </select>
                    {field.subtitle ? (
                      <div className="text-sm text-gray-500">
                        {field.subtitle}
                      </div>
                    ) : null}
                  </div>
                );
              case "boolean":
                return (
                  <div className="flex flex-col gap-2" key={field.db_name}>
                    <div className="text-lg font-semibold">
                      {field.name}{" "}
                      {field.required ? (
                        <span className="text-red-400">(Required)</span>
                      ) : null}
                    </div>
                    <select
                      className="w-full outline-none border rounded-md p-2 shadow-sm"
                      value={
                        sectionData[field.db_name] !== undefined &&
                        sectionData[field.db_name] !== null
                          ? sectionData[field.db_name].toString()
                          : ""
                      }
                      onChange={(e) => {
                        setSectionData({
                          ...sectionData,
                          [field.db_name]: e.target.value,
                        });
                        console.log("set section data after change");
                      }}
                    >
                      <option value={""}>---</option>
                      <option value={"true"}>Yes</option>
                      <option value={"false"}>No</option>
                    </select>
                    {field.subtitle ? (
                      <div className="text-sm text-gray-500">
                        {field.subtitle}
                      </div>
                    ) : null}
                  </div>
                );
              case "textarea":
                return (
                  <div className="flex flex-col gap-2" key={field.db_name}>
                    <div className="text-lg font-semibold">
                      {field.name}{" "}
                      {field.required ? (
                        <span className="text-red-400">(Required)</span>
                      ) : null}
                    </div>
                    <textarea
                      className="w-full outline-none border rounded-md p-2 shadow-sm"
                      value={sectionData[field.db_name] || ""}
                      onChange={(e) => {
                        console.log("on change");
                        setSectionData({
                          ...sectionData,
                          [field.db_name]: e.target.value,
                        });
                      }}
                    />
                    {field.subtitle ? (
                      <div className="text-sm text-gray-500">
                        {field.subtitle}
                      </div>
                    ) : null}
                  </div>
                );
              case "date":
                return (
                  <div className="flex flex-col gap-2" key={field.db_name}>
                    <div className="text-lg font-semibold">
                      {field.name}{" "}
                      {field.required ? (
                        <span className="text-red-400">(Required)</span>
                      ) : null}
                    </div>
                    <input
                      type="date"
                      className="w-full outline-none border rounded-md p-2 shadow-sm"
                      value={sectionData[field.db_name] || ""}
                      onChange={(e) => {
                        console.log("on change");
                        setSectionData({
                          ...sectionData,
                          [field.db_name]: e.target.value.toString(),
                        });
                      }}
                    />
                    {field.subtitle ? (
                      <div className="text-sm text-gray-500">
                        {field.subtitle}
                      </div>
                    ) : null}
                  </div>
                );

              case "multiple-select":
                return (
                  <div key={field.db_name} className="flex flex-col gap-2">
                    <div className="text-lg font-semibold">
                      {field.name}{" "}
                      {field.required ? (
                        <span className="text-red-400">(Required)</span>
                      ) : null}
                    </div>
                    {field.subtitle ? (
                      <div className="text-sm text-gray-500">
                        {field.subtitle}
                      </div>
                    ) : null}
                    {field.options?.map((option) => {
                      return (
                        <label key={option}>
                          <input
                            className="mr-3"
                            key={option}
                            type="checkbox"
                            value={option}
                            checked={
                              sectionData[field.db_name]
                                ? sectionData[field.db_name]
                                    .split(",")
                                    .includes(option)
                                : false
                            }
                            onChange={(e) => {
                              let checked = e.target.checked;
                              let selectedValues: string[] = [];
                              if (
                                sectionData[field.db_name] !== undefined &&
                                sectionData[field.db_name] !== null
                              ) {
                                selectedValues =
                                  sectionData[field.db_name].split(",");
                              }
                              if (checked) {
                                selectedValues.push(option);
                              } else {
                                selectedValues = selectedValues.filter(
                                  (x: string) => x !== option
                                );
                              }

                              console.log(selectedValues);
                              setSectionData({
                                ...sectionData,
                                [field.db_name]: selectedValues.toString(),
                              });
                              console.log("updated state");
                            }}
                          />
                          {option}
                        </label>
                      );
                    })}
                  </div>
                );
            }
            return null;
          })
        : null}
      <GrayButton disabled={!complete} onClick={updateSection}>
        Save Changes
      </GrayButton>
      {!complete ? (
        <div className="text-sm text-gray-400 m-auto">
          Please complete all required fields.
        </div>
      ) : null}
    </div>
  );
}
