import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { PostType } from "../../types";
import { API_URL } from "../../util/routes";

const Post = ({
  post,
  hideHeader,
}: {
  post: PostType;
  hideHeader?: boolean;
}) => {
  const [authorName, setAuthorName] = useState<string>();
  useEffect(() => {
    async function getAuthor() {
      const response = await axios.get(
        `${API_URL}/profiles/profile/${post.author}`
      );
      setAuthorName(`${response.data.first_name} ${response.data.last_name}`);
    }
    getAuthor();
  }, [post]);
  return (
    <div>
      {hideHeader ? null : (
        <div className="flex flex-row gap-3 items-center">
          <div className="font-semibold">{authorName}</div>
          <div className="text-gray-400 text-sm">
            {moment(post.date).fromNow()}
          </div>
        </div>
      )}
      <div className="ml-1 p-2 border-l-2">
        <div>{post.body}</div>
      </div>
    </div>
  );
};

export default Post;
