import { useState } from "react";
import { Link, Route, Routes } from "react-router-dom";
import { ProfileSectionType } from "../../types";
import RectangleRow from "../RectangleRow";
import TabButton from "../TabButton";
import ProfileEditor from "./ProfileEditor";

export default function EditProfile({
  availableSections,
}: {
  availableSections: ProfileSectionType[];
}) {
  const [page, setPage] = useState("Contact");
  return (
    <div className="w-full flex flex-col items-center gap-3">
      <div className="text-lg">Edit Profile</div>
      <RectangleRow extraStyles="w-5/7 flex-wrap justify-center">
        {availableSections.map((profileSection) => {
          return (
            <Link key={profileSection.db_name} to={profileSection.db_name}>
              <TabButton
                disabled={page === profileSection.db_name}
                onClick={() => {
                  setPage(profileSection.db_name);
                }}
              >
                {profileSection.name}
              </TabButton>
            </Link>
          );
        })}
      </RectangleRow>
      <Routes>
        <Route path=":section_name" element={<ProfileEditor />} />
      </Routes>
    </div>
  );
}
